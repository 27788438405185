import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { hasHourTime, timeToDescriptive } from '../utils/time'
import { capitalizeAWord } from '../utils/words'

class BlogIndex extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const posts = data.allMdx.edges


        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="All posts" />
                {posts.map(({ node }) => {
                    const {date, prettyDate} = node.frontmatter
                    const title = node.frontmatter.title || node.fields.slug

                    return (
                        <article key={node.fields.slug}>
                            <header>
                                <h3
                                    style={{
                                        marginBottom: rhythm(1 / 4),
                                    }}
                                >
                                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                                        {title}
                                    </Link>
                                </h3>
                                <small>{prettyDate}{
                                    hasHourTime(date) ?
                                        ` - ${capitalizeAWord(timeToDescriptive(date))}` : null 
                                }</small>
                            </header>
                            <section>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: node.frontmatter.description || node.excerpt,
                                    }}
                                />
                            </section>
                        </article>
                    )
                })}
            </Layout>
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
      site {
          siteMetadata {
              title
          }
      }
      allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
          edges {
              node {
                  excerpt
                  fields {
                      slug
                  }
                  frontmatter {
                      prettyDate: date(formatString: "MMMM DD, YYYY")
                      date
                      title
                      description
                  }
              }
          }
      }
  }
`
